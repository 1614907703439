export const useToastStore = defineStore('toast-store', {
    state: () => {
        return {
            toasts: [],
        };
    },

    getters: {
        activeToasts() {
            return this.toasts;
        }
    },

    actions: {
        add(message, duration, icon) {
            this.toasts.push({
                id: Math.floor(Math.random() * 10000),
                message,
                icon
            });

            if (duration) {
                setTimeout(() => {
                    this.remove(this.toasts[this.toasts.length - 1].id);
                }, duration);
            }
        },
        remove(id) {
            this.toasts = this.toasts.filter((toast) => toast.id !== id);
        }
    }
});
